@import "../variables";

@import url('https://fonts.googleapis.com/css2?family=Gotu&display=swap');

@font-face {
    font-family:"Mallory Light";
    src:url($fa-font-path + "/Mallory-Light.woff2") format("woff2"),url($fa-font-path + "/Mallory-Light.woff") format("woff"),url($fa-font-path + "/Mallory-Light.otf") format("opentype");
    font-style:normal;font-weight:400;
}

@font-face {
    font-family:"Mallory Book";
    src:url($fa-font-path+"/Mallory-Book.woff2") format("woff2"),url($fa-font-path + "/Mallory-Book.woff") format("woff"),url($fa-font-path + "/Mallory-Book.otf") format("opentype");
    font-style:normal;font-weight:400;
}


@font-face {
    font-family:"Mallory Bold";
    src:url($fa-font-path + "/Mallory-Bold.woff2") format("woff2"),url($fa-font-path + "/Mallory-Bold.woff") format("woff"),url($fa-font-path + "/Mallory-Bold.otf") format("opentype");
    font-style:normal;font-weight:400;
}
